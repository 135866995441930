@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
/*濃いベージュ*/
/*green*/
/*薄いベージ*/
/*オレンジ*/
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #333333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-style: normal;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 16px;
  min-width: 100%;
  overflow: hidden;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 80px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

.col_main2 {
  width: 70% !important;
  margin-top: 100px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .col_main2 {
    width: 100% !important;
  }
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

a {
  transition: 1s;
}

.imgR, .imgL {
  float: none	!important;
  margin-left: 0;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
  margin-right: 0 !important;
}

@media only screen and (max-width: 640px) {
  .imgC {
    width: 100% !important;
  }
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

#header {
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #005bac;
}

#header .hed_R {
  padding: 15px;
}

@media only screen and (max-width: 834px) {
  #header .hed_R {
    display: none;
  }
}

#header .hed_R .contwrap {
  display: flex;
  justify-content: space-between;
}

#header .hed_R .telwrap {
  background: #fff;
  width: 265px;
  padding: 5px 10px;
  height: 55px;
}

#header .hed_R .telwrap .tel2 {
  font-size: 1.25;
  line-height: 1em;
  text-align: left;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #header .hed_R .telwrap .tel2 {
    font-size: 26px;
  }
}

#header .hed_R .telwrap .tel2 a {
  color: #333333;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #header .hed_R .telwrap .tel2 a {
    display: flex;
    align-items: center;
  }
}

#header .hed_R .telwrap .tel2 img {
  margin-right: 7px;
}

#header .hed_R .telwrap .time {
  font-size: 13px;
  text-align: center;
}

#header .hed_R .hed_cont a {
  background: #009c6f;
  padding: 14px;
  display: block;
  color: #ffffff;
  font-weight: bold;
  width: 265px;
  height: 55px;
}

#header .hed_L {
  background: #fff;
  padding: 0 10px;
  width: 25%;
  height: 124px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  #header .hed_L {
    width: 100%;
    height: 84px;
  }
  #header .hed_L img {
    width: 80% !important;
  }
}

@media only screen and (max-width: 834px) {
  #header {
    display: block;
  }
}

#header #siteID {
  text-align: center;
}

@media only screen and (max-width: 834px) {
  #header #siteID {
    text-align: center;
  }
}

#header #siteID a {
  opacity: 1;
  display: inline-block;
}

.fixed {
  position: fixed !important;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  background: rgba(250, 250, 250, 0.9);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 834px) {
  #nav_global {
    display: none;
  }
}

#nav_global .inner {
  padding: 0;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul .pcnone {
  display: none;
}

#nav_global ul .spnone {
  display: block;
}

@media only screen and (max-width: 640px) {
  #nav_global ul .spnone {
    display: none;
  }
}

#nav_global ul li {
  z-index: 1;
  position: relative;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #nav_global ul li {
    font-size: 14px;
  }
}

#nav_global ul li a {
  display: block;
  color: #ffffff;
  line-height: 1.2em;
  padding: 10px;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #nav_global ul li a {
    font-size: 14px;
  }
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

#nav_global ul ul {
  position: absolute;
  width: 200px;
  z-index: 100;
  display: block;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

#nav_global ul ul li {
  border-bottom: 1px solid #009c6f;
}

#nav_global ul ul li a {
  color: #333333;
  padding: 15px 0;
}

.mainArea img {
  width: 100% !important;
  height: auto !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.pankuzu {
  display: flex;
  align-items: center;
  justify-content: center;
}

#local-keyvisual {
  position: relative;
  background-position: center top;
  z-index: 0;
}

#local-keyvisual:empty {
  display: none;
}

#local-keyvisual h1 {
  font-size: 3em;
  z-index: 1;
  position: relative;
  text-align: center;
  line-height: 1.2em;
  width: 100%;
  margin: 0 auto;
  padding: 3% 0;
  font-weight: bold;
  background: url(../images/home/cont2_bg.jpg);
  background-size: cover;
}

#local-keyvisual h1:empty {
  display: none;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2em;
    padding: 8% 0;
  }
}

#slider {
  position: relative;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center top;
}

#local-keyvisual #local-keyvisual-bg:empty, #local-keyvisual .local-keyvisual-bg:empty {
  display: none;
}

.pan1 {
  margin: 20px 0 50px;
  text-align: center;
}

.pan1 a {
  color: #333;
}

.toggleMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 49px;
  width: 49px;
  position: fixed;
  top: 10px;
  right: 5px;
  z-index: 100;
  transition: 0.5s ease-in-out;
  background: #005bac;
}

.hide {
  transform: translateY(-200%);
}

.toggleMenu .bars {
  margin: 0 30px 0 15px;
  height: 22px;
  width: 35px;
  position: relative;
}

.toggleMenu a:hover {
  opacity: 1;
}

.toggleMenu .bars span {
  height: 3px;
  background-color: #fff;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  position: absolute;
  left: -5px;
}

.toggleMenu .bars span:nth-of-type(1) {
  width: 30px;
  top: 0;
}

.toggleMenu .bars span:nth-of-type(2) {
  width: 30px;
  top: 10px;
}

.toggleMenu .bars span:nth-of-type(3) {
  width: 30px;
  top: 20px;
}

.toggleMenu.isActive .bars span {
  right: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(1), .toggleMenu.isActive .bars span:nth-of-type(3) {
  width: 30px;
  top: 10px;
}

.toggleMenu.isActive .bars span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggleMenu.isActive .bars span:nth-of-type(2) {
  opacity: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.overlayMenu {
  width: 100%;
  -webkit-transition: -webkit-transform .8s ease;
  transition: -webkit-transform .8s ease;
  transition: transform .8s ease;
  transition: transform .8s ease, -webkit-transform .8s ease;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
}

.overlayMenu.isOpened {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.overlayMenu > .outer {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: #005bac;
  padding: 80px 30px !important;
}

.overlayMenu > .outer li {
  position: relative;
}

.overlayMenu > .outer li a {
  color: #ffffff;
  padding: 10px;
  display: block;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.2em;
  border-bottom: 1px solid #5999d2;
}

.overlayMenu > .outer li ul li a {
  font-size: 16px;
  font-weight: normal;
}

.overlayMenu > .outer .sp_info .telwrap {
  background: #fff;
  width: 265px;
  padding: 5px 10px;
  height: 55px;
  margin: 15px auto;
}

.overlayMenu > .outer .sp_info .telwrap .tel2 {
  font-size: 1.25;
  line-height: 1em;
  text-align: left;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .sp_info .telwrap .tel2 {
    font-size: 26px;
  }
}

.overlayMenu > .outer .sp_info .telwrap .tel2 a {
  color: #333333;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .sp_info .telwrap .tel2 a {
    display: flex;
    align-items: center;
  }
}

.overlayMenu > .outer .sp_info .telwrap .tel2 img {
  margin-right: 7px;
}

.overlayMenu > .outer .sp_info .telwrap .time {
  font-size: 13px;
  text-align: center;
}

.overlayMenu > .outer .sp_info .hed_cont a {
  background: #009c6f;
  padding: 14px;
  display: block;
  color: #ffffff;
  font-weight: bold;
  width: 265px;
  height: 55px;
}

.overlayMenu > .outer .sp_info .hed_cont a {
  margin: 0 auto;
}

.overlayMenu > .outer .toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  display: block;
  height: 44px;
  width: 44px;
  background: #044279;
  border-bottom: 1px solid #5999d2;
}

.overlayMenu > .outer .sp_btn {
  position: relative;
  width: 44px;
  height: 44px;
  display: block;
}

.overlayMenu > .outer .sp_btn:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  width: 50px;
  height: 1px;
  background-color: #ffffff;
  content: '';
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .sp_btn:before {
    width: 12px;
  }
}

.overlayMenu > .outer .sp_btn:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  width: 50px;
  height: 1px;
  background-color: #ffffff;
  content: '';
  transition: transform .3s;
  transform: rotate(-90deg);
}

@media only screen and (max-width: 834px) {
  .overlayMenu > .outer .sp_btn:after {
    width: 12px;
  }
}

.overlayMenu > .outer .on_click:after {
  transform: rotate(0);
}

.overlayMenu:before, .overlayMenu:after {
  content: "";
  height: 20px;
  background-size: cover;
  position: absolute;
  left: 10px;
  right: 10px;
}

.overlayMenu:before {
  top: 0;
}

.overlayMenu:after {
  bottom: 0;
}

@media (max-width: 835px) {
  .pcOnly {
    display: none !important;
  }
}

@media (min-width: 835px) {
  .smOnly {
    display: none !important;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  text-align: center;
  background: #005bac;
}

@media only screen and (max-width: 834px) {
  footer#global_footer {
    background: none;
  }
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer #nav_footer {
  padding: 0;
}

footer#global_footer #nav_footer ul {
  margin-right: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer ul {
    margin-right: 0;
  }
}

footer#global_footer #nav_footer ul li {
  text-align: left;
  position: relative;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer ul li {
    text-align: center;
  }
}

footer#global_footer #nav_footer ul li a {
  text-decoration: none;
  line-height: 2em;
  padding: 0 20px 0 20px;
  display: block;
  color: #ffffff;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  footer#global_footer #nav_footer ul li a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer ul li a {
    background: #005bac;
    color: #ffffff;
    padding: 10px;
    margin: 10px 0;
  }
}

footer#global_footer #nav_footer ul li a:hover {
  opacity: 0.6;
}

footer#global_footer #nav_footer .nav2 li {
  color: #ffffff;
  position: relative;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer .nav2 li:first-of-type a {
    color: #333333 !important;
    border-bottom: 1px solid #005bac;
    background: none;
    border-radius: 0;
    font-weight: bold;
  }
}

footer#global_footer #nav_footer .nav2 li:first-of-type:before {
  font-family: "Font Awesome 5 pro";
  content: "\f138";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  left: 0;
  top: 4px;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer .nav2 li:first-of-type:before {
    color: #005bac;
    top: 13px;
  }
}

footer#global_footer #nav_footer .nav1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer .nav1 {
    display: block;
  }
}

footer#global_footer #nav_footer .nav1 li {
  position: relative;
  color: #ffffff;
}

footer#global_footer #nav_footer .nav1 li:before {
  font-family: "Font Awesome 5 pro";
  content: "\f138";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  left: 0;
  top: 4px;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer .nav1 li:before {
    display: none;
    top: 13px;
  }
}

footer#global_footer #nav_footer .nav_sub {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@media only screen and (max-width: 834px) {
  footer#global_footer #nav_footer .nav_sub {
    display: block;
  }
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff !important;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

#copy {
  color: #fff;
}

.small_bg {
  background: #005bac;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  opacity: 1;
}

.pagetop a {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #009c6f;
  color: #ffffff;
  font-weight: bold;
  display: block;
  padding: 14px;
  font-size: 1.5em;
}

.footer1 {
  padding: 60px 10px 30px;
}

@media only screen and (max-width: 834px) {
  .footer1 {
    padding: 0 10px;
  }
}

.footer2 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  border-top: 1px solid #044279;
}

.footer2 .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 10px 60px;
}

@media only screen and (max-width: 640px) {
  .footer2 .inner {
    display: block;
  }
}

.footer2 .inner .ft_L {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .footer2 .inner .ft_L {
    width: 100%;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .footer2 .inner .ft_L {
    width: 60%;
  }
}

.footer2 .inner .ft_R {
  width: 48%;
}

.footer2 .inner .ft_R ul {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 640px) {
  .footer2 .inner .ft_R ul {
    display: block;
  }
}

.footer2 .inner .ft_R ul li:last-of-type {
  margin-left: 15px;
}

@media only screen and (max-width: 640px) {
  .footer2 .inner .ft_R ul li:last-of-type {
    margin-left: 0;
  }
}

@media only screen and (max-width: 834px) {
  .footer2 .inner .ft_R {
    width: 100%;
    margin-top: 30px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .footer2 .inner .ft_R {
    width: 40%;
  }
}

.footer2 .inner .ft_R iframe {
  max-height: 217px;
}

.footer2 .inner .add {
  text-align: left;
  color: #ffffff;
}

@media only screen and (max-width: 640px) {
  .footer2 .inner .add {
    text-align: center;
    color: #333333;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .footer2 .inner .add {
    color: #333333;
  }
}

.title_01 h2 {
  font-size: 2em;
  font-weight: bold;
  line-height: 1.5em;
}

@media only screen and (max-width: 640px) {
  .title_01 h2 {
    font-size: 1.25em;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .title_01 h2 {
    font-size: 1.8em;
  }
}

.title_01 h2 br {
  display: none;
}

@media only screen and (max-width: 834px) {
  .title_01 h2 br {
    display: block;
  }
}

.cont_01 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  background: url(../images/home/cont2_bg.jpg);
  background-size: cover;
  padding: 70px 3%;
}

.cont_01 .in {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cont_01 .in .next_list {
  width: 18%;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .cont_01 .in .next_list {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_01 .in .next_list {
    width: 48% !important;
    margin-bottom: 20px;
  }
}

.cont_01 .in .next_list .list_img a {
  width: 100% !important;
  height: auto !important;
  display: block;
  border-bottom: 1px solid #044279;
}

.cont_01 .in .next_list .list_img img {
  width: 100% !important;
}

.cont_01 .in .next_list .list {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}

@media only screen and (max-width: 834px) {
  .cont_01 .in .next_list .list {
    position: inherit;
    left: unset;
    right: unset;
  }
}

@media only screen and (max-width: 834px) {
  .cont_01 .in .next_list .list ul {
    display: block !important;
  }
}

.cont_01 .in .next_list .list ul li a {
  background: #005bac;
  color: #ffffff;
  display: block;
  padding: 10px;
  border-bottom: 1px solid #044279;
}

.cont_01 .in .next_list .list ul li a:hover {
  opacity: 1 !important;
  background: #669DCD;
}

.cont_02 {
  background: url(../images/home/cont1_bg.jpg);
  background-size: cover;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  padding: 190px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5%;
}

@media only screen and (max-width: 834px) {
  .cont_02 {
    padding-right: 0;
    background-position: 10%;
    display: block;
  }
}

@media only screen and (max-width: 834px) {
  .cont_02 .in {
    background: rgba(255, 255, 255, 0.8);
    padding: 30px 15px;
  }
}

.cont_03 {
  display: flex;
  justify-content: space-between;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .cont_03 {
    display: block;
  }
}

.cont_03 .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_03 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_03 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_03 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.cont_03 .flxR {
  position: relative;
}

.cont_03 .flxR:before {
  content: url(../images/home/drugs_bg.png);
  position: absolute;
  right: 0;
  bottom: -100px;
}

.cont_04 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .cont_04 {
    display: block;
  }
}

.cont_04 .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_04 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_04 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_04 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.cont_04 .flxR {
  position: relative;
}

.cont_04 .flxR:before {
  content: url(../images/home/scope_bg.png);
  position: absolute;
  right: 0;
  bottom: -100px;
}

.cont_05 {
  display: flex;
  justify-content: space-between;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .cont_05 {
    display: block;
  }
}

.cont_05 .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_05 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_05 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_05 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.cont_05:before {
  content: url(../images/home/supple_bg.png);
  position: absolute;
  right: 0;
  bottom: 0;
}

.bnrbox {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox {
    margin-left: -2.4390%;
  }
}

@media only screen and (max-width: 640px) {
  .bnrbox {
    justify-content: space-between;
    margin-left: 0;
  }
}

.bnrbox .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrbox .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .bnrbox .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.bnrbox .box img {
  width: 100% !important;
}

.dl_name dt {
  line-height: 1em;
  border: none !important;
  background: #f41010 !important;
  padding: 5px 10px !important;
  border-radius: 5px;
  color: #ffffff;
  flex-direction: inherit !important;
  text-align: center;
  align-items: center !important;
}

@media only screen and (max-width: 834px) {
  .dl_name dt {
    width: 120px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .dl_name dt {
    width: 120px !important;
  }
}

.dl_name dd {
  border: none !important;
  padding: 5px !important;
}

.txt {
  line-height: 1.875em;
}

.btn a {
  display: block;
  padding: 15px;
  color: #ffffff;
  position: relative;
  background: #009c6f;
  max-width: 330px;
  line-height: 1.2em;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .btn a {
    max-width: 100%;
  }
}

.btn a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f138";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 3px;
  top: 14px;
  font-weight: bold;
}

.gmap {
  height: 100%;
}

.gmap iframe {
  width: 100%;
  height: 316px;
}

.news {
  max-height: 240px;
  overflow-y: auto;
}

@media only screen and (max-width: 834px) {
  .news {
    padding-left: 0;
  }
}

.news dt {
  border: none !important;
  padding: 0 !important;
}

@media only screen and (max-width: 834px) {
  .news dt {
    width: 100% !important;
    display: block !important;
  }
}

.news dd {
  border: none !important;
  padding: 0 !important;
}

@media only screen and (max-width: 834px) {
  .news dd {
    width: 100% !important;
  }
}

.news dl {
  border-bottom: 1px solid #ccc;
  padding: 25px 0 !important;
}

@media only screen and (max-width: 834px) {
  .news dl {
    width: 100% !important;
    display: block !important;
  }
}

.news dt {
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-weight: bold;
  color: #602e05;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #777;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

.title_02 h2 {
  font-size: 1.75em;
  position: relative;
  padding: 10px 10px 10px 25px;
  line-height: 1.2em;
  border: 1px solid #ccc;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .title_02 h2 {
    font-size: 1.3em;
  }
}

.title_02 h2:before {
  content: '';
  width: 10px;
  height: 20px;
  border-radius: 5px;
  position: absolute;
  left: 7px;
  top: 18px;
  background: #005bac;
}

@media only screen and (max-width: 834px) {
  .title_02 h2:before {
    top: 13px;
  }
}

.title_03 h2 {
  font-weight: bold;
  font-size: 1.5em;
}

.img_sodhiki img {
  border: 1px solid #595959;
}

.sub_title_01 h3 {
  font-size: 1.25em;
  font-weight: bold;
  background: #009c6f;
  padding: 10px;
  color: #ffffff;
}

.sec_01 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_01 {
    display: block;
  }
}

.sec_01 .flxL {
  width: 33%;
}

@media only screen and (max-width: 640px) {
  .sec_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.sec_01 .flxR {
  width: 60%;
}

@media only screen and (max-width: 640px) {
  .sec_01 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_01_2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_01_2 {
    display: block;
  }
}

.sec_01_2 .flxL {
  width: 33%;
}

@media only screen and (max-width: 640px) {
  .sec_01_2 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.sec_01_2 .flxR {
  width: 60%;
}

@media only screen and (max-width: 640px) {
  .sec_01_2 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_01_2 .flxL img {
  border: 1px solid #595959;
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sec_02 .cont {
  display: block !important;
  width: 31%;
}

@media only screen and (max-width: 640px) {
  .sec_02 .cont {
    width: 100% !important;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_02 .cont {
    width: 48% !important;
    margin-bottom: 20px;
  }
}

.sec_02 .cont .box {
  width: 100% !important;
}

.sec_02 .cont .box img {
  width: 100% !important;
}

.sec_02 .cont .box:nth-of-type(1) div {
  background: #005bac;
  color: #ffffff;
  padding: 10px;
  font-weight: bold;
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eaf4fd;
  padding: 30px 15px;
  max-width: 800px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
}

.sec_03 .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .sec_03 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.sec_03 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .sec_03 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_03 .flxR .tel2 div {
  font-weight: bold;
  font-size: 2em;
}

.sec_03 .flxR .tel2 div a {
  color: #333333;
  font-weight: bold;
}

.sec_03 .flxR .fax div {
  font-size: 1.25em;
  font-weight: bold;
}

.sec_04 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_04 {
    display: block;
  }
}

.sec_04 .flxL {
  width: 21%;
}

@media only screen and (max-width: 640px) {
  .sec_04 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.sec_04 .flxR {
  width: 78%;
}

@media only screen and (max-width: 640px) {
  .sec_04 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_05 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_05 {
    display: block;
  }
}

.sec_05 .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .sec_05 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.sec_05 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .sec_05 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_05 .date {
  font-size: 20px;
  margin-right: 15px;
  color: #005bac;
}

.sec_05 .btn a {
  background: #005bac;
}

.sec_05_3 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_05_3 {
    display: block;
  }
}

.sec_05_3 .flxL {
  width: 25%;
}

@media only screen and (max-width: 640px) {
  .sec_05_3 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.sec_05_3 .flxR {
  width: 70%;
}

@media only screen and (max-width: 640px) {
  .sec_05_3 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_05_3 .date {
  font-size: 20px;
  margin-right: 15px;
  color: #005bac;
}

.sec_05_3 .btn a {
  background: #005bac;
}

.imgnone .date {
  font-size: 20px;
  margin-right: 15px;
  color: #005bac;
}

.sec_05_2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_05_2 {
    display: block;
  }
}

.sec_05_2 .flxL {
  width: 34%;
}

@media only screen and (max-width: 640px) {
  .sec_05_2 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.sec_05_2 .flxR {
  width: 60%;
}

@media only screen and (max-width: 640px) {
  .sec_05_2 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_05_2 .date {
  font-size: 20px;
  margin-right: 15px;
  color: #005bac;
}

.sec_05_2 .btn a {
  background: #005bac;
}

.imgbox .box {
  width: 48%;
  justify-content: space-between;
  align-items: center;
  background: #f7f7f7;
  padding: 15px;
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100%;
  }
}

.imgbox .box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.imgbox2 {
  justify-content: flex-start;
}

.imgbox2 .box {
  width: 32%;
  margin-right: 2%;
  margin-bottom: 2%;
}

@media only screen and (max-width: 640px) {
  .imgbox2 .box {
    width: 100%;
    margin-right: 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox2 .box {
    width: 48%;
  }
}

.imgbox2 .box:nth-of-type(3n) {
  margin-right: 0;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox2 .box:nth-of-type(3n) {
    margin-right: 2%;
  }
}

.imgbox2 .box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.flx_cont {
  align-items: center;
}

.flx_cont .box {
  width: 30%;
  margin-right: 2%;
}

@media only screen and (max-width: 640px) {
  .flx_cont .box {
    width: 100% !important;
    margin-bottom: 2%;
  }
}

.flx_cont .box:nth-of-type(3) {
  text-align: center;
  margin: 0 auto;
}

.flx_cont .box:nth-of-type(3) a {
  display: block;
  padding: 15px;
  color: #ffffff;
  position: relative;
  background: #009c6f;
  max-width: 330px;
  line-height: 1.2em;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .flx_cont .box:nth-of-type(3) a {
    max-width: 100%;
  }
}

.flx_cont .box:nth-of-type(3) a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f138";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 3px;
  top: 14px;
  font-weight: bold;
}

.flx_cont .box:nth-of-type(3) a {
  background: #005bac;
}

.list_01 li a {
  padding: 10px;
  display: block;
}

.list_01 li a:hover {
  text-decoration: underline;
}

.list_01 li:nth-of-type(odd) a {
  background: #eaf4fd;
}

.list_02 li {
  background: #eaf4fd;
  margin: 5px 0;
  padding: 10px;
}

.cont_txt .box {
  width: 100% !important;
}

.cont_txt .box:nth-of-type(1) div {
  font-size: 1.25em;
  font-weight: bold;
  border-bottom: 1px solid #009c6f;
  padding-bottom: 5px;
  margin-bottom: 5px;
  line-height: 1.2em;
}

.cont_txt .box:nth-of-type(1) div span {
  color: #009c6f;
}

.txt_h3 h3 {
  font-weight: bold;
}

.btnlist ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.btnlist li {
  width: 32%;
  float: none !important;
  margin-bottom: 2%;
  margin-right: 2%;
}

.btnlist li:nth-of-type(4n) {
  margin-right: 0;
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .btnlist li:nth-of-type(4n) {
    margin-right: 2%;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .btnlist li:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnlist li:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  .btnlist li {
    margin-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  .btnlist li {
    width: 100%;
    margin-bottom: 2%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .btnlist li {
    width: 49%;
    margin-bottom: 2%;
  }
}

@media screen and (min-width: 834px) and (max-width: 960px) {
  .btnlist li {
    width: 49%;
    margin-bottom: 2%;
  }
}

.btnlist li a {
  background: #009c6f;
  padding: 10px;
  display: block;
  color: #ffffff;
  position: relative;
}

.btnlist li a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f105";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 2%;
  /*アイコンの位置*/
  top: auto;
  /*アイコンの位置*/
}

@media only screen and (max-width: 640px) {
  .dl_01 dt {
    width: 100% !important;
    display: block !important;
    border-right: 1px solid #ccc !important;
  }
}

@media only screen and (max-width: 640px) {
  .dl_01 dd {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
}

.dl_02 dt {
  border: none !important;
  border-bottom: 1px solid #005bac !important;
}

@media only screen and (max-width: 640px) {
  .dl_02 dt {
    width: 100% !important;
    display: block !important;
  }
}

.dl_02 dd {
  border: none !important;
  border-bottom: 1px solid #eaf4fd !important;
}

@media only screen and (max-width: 640px) {
  .dl_02 dd {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .dl_02 dl {
    display: block !important;
  }
}

.td_01 .td_top {
  background: #005bac;
  color: #ffffff;
}

.td_01 .td_side {
  background: #eaf4fd;
}

.td_02 br {
  display: none;
}

@media only screen and (max-width: 640px) {
  .td_02 br {
    display: block;
  }
}

.td_02 .td_top {
  background: #005bac;
  color: #ffffff;
}

.td_02 .td_side {
  background: #eaf4fd;
}

.mail {
  display: none;
}

.cont_tel div .tel2 {
  color: #005bac;
  font-weight: bold;
  font-size: 2em;
}

.cont_tel div .tel2 a {
  color: #005bac;
  font-weight: bold;
}

.fax div {
  font-size: 1.3em;
  font-weight: bold;
}

.qa {
  border-bottom: 1px dashed #ccc;
  padding: 15px 0;
}

.qa .box:nth-of-type(1) {
  width: 100% !important;
  border: none !important;
  position: relative;
  padding: 20px 0 20px 45px !important;
  position: relative;
  font-size: 1.25em;
}

.qa .box:nth-of-type(1):before {
  content: "Q";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #009c6f;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 15px;
}

@media only screen and (max-width: 834px) {
  .qa .box:nth-of-type(1) {
    padding-right: 45px !important;
  }
}

.qa .box:nth-of-type(2) {
  width: 100% !important;
  border: none !important;
  padding: 15px 0 15px 45px !important;
  position: relative;
  line-height: 2.25em;
}

.qa .box:nth-of-type(2):before {
  content: "A";
  display: inline-block;
  line-height: 40px;
  padding: 0em;
  color: white;
  background: #005bac;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-right: 7px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}

.cont_form a {
  color: #333333;
}

.cont_form a:hover {
  text-decoration: underline;
}

.confirm {
  margin-top: 30px;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 1px solid #005bac;
  font-family: fot-tsukuardgothic-std, sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 640px) {
  .policy .center {
    font-size: 80%;
  }
}

#TRANS_ANNOT {
  display: none;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}

.sns {
  margin-top: 50px;
}

#media {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

#media .row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

#media .row .box {
  width: 31%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 640px) {
  #media .row .box {
    width: 100% !important;
  }
}

#media .row .box h3 {
  text-align: left;
  margin-top: 15px;
}

#media .row .box p {
  text-align: left;
}

#media .row .box img {
  width: 380px !important;
  height: 380px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
